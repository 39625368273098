import { Point } from '../Point';
import { BezierPath } from '../BezierPath';
import {
  CanvasMode,
  CursorStyle,
  KeyCodes,
  MouseButtons,
} from '@/safeAreaTool/types/types';
import { Canvas } from '@/safeAreaTool/models/canvas/Canvas';
import { Position } from '@/safeAreaTool/types/Position';
import { parseStringToSVG } from '@/safeAreaTool/utils/svgUtils';
import { InitPoint } from '@/safeAreaTool/types/InitPoint';

/**
 * Canvas with bezier path logic
 */
export class SafeLineCanvas extends Canvas {
  private canvasMode: CanvasMode = CanvasMode.Adding;
  private bezierPath?: BezierPath;

  public toSvg(): SVGElement {
    if (!this.canvasImage || (this.canvasImage && !this.canvasImage.imageElem))
      throw 'Image must be loaded';

    const xmlns = 'http://www.w3.org/2000/svg';
    const svgElem = super.toSvg();

    const g = document.createElementNS(xmlns, 'g');
    svgElem.appendChild(g);

    const pathVal =
      this.bezierPath?.toPath(
        this.canvasImage.imageScale,
        this.canvasImage.imageTranslate,
      ) || '';
    const path = document.createElementNS(xmlns, 'path');
    path.setAttributeNS(null, 'fill', 'none');
    path.setAttributeNS(null, 'stroke', '#000000');
    path.setAttributeNS(null, 'stroke-width', '1px');
    path.setAttributeNS(null, 'd', pathVal);

    g.appendChild(path);
    return svgElem;
  }

  protected async init(imageUrl: string, safeAreaSvg?: string) {
    await super.init(imageUrl);
    const points = safeAreaSvg ? this.svgStringToBezierPath(safeAreaSvg) : [];
    this.initBezierPath(points);
    this.render();

    // attach default events
    this.canvas.addEventListener('mousedown', e => this.handleDown(e));
    this.canvas.addEventListener('mouseup', e => this.handleUp(e));
    this.canvas.addEventListener('mousemove', e => this.handleMouseMove(e));
    this.attachKeyDownEvent();
  }

  private initBezierPath(points: InitPoint[]) {
    if (!this.bezierPath) this.bezierPath = new BezierPath();
    this.bezierPath.initFromArray(points);
  }

  private handleDown(e: MouseEvent) {
    if (e.button !== MouseButtons.LEFT) return;
    const pos = this.getMousePosition(e);

    const posIsOnNode = !!this.bezierPath?.getNodeByPos(pos);
    //deselect if any
    const deselected = this.handleDownDeselect();

    if (posIsOnNode) {
      // select next if any
      this.handleDownSelect(pos);
    } else if (!deselected) {
      // if nothing was deselected, start adding mode
      this.handleDownAdd(pos);
    }
  }

  private handleUp(e: MouseEvent) {
    e.stopPropagation();
    if (e.button !== MouseButtons.LEFT) return;
    if (this.canvasMode == CanvasMode.Dragging) {
      this.canvasMode = CanvasMode.Adding;
    }
  }

  private handleMouseMove(e: MouseEvent) {
    e.stopPropagation();
    const pos = this.getMousePosition(e);
    if (this.canvasMode == CanvasMode.Dragging) {
      this.updateSelected(pos);
      return;
    }

    const posIsOnNode = !!this.bezierPath?.getNodeByPos(pos);
    if (posIsOnNode) {
      this.canvas.style.cursor = CursorStyle.POINTER;
    } else {
      this.canvas.style.cursor = CursorStyle.DEFAULT;
    }
  }

  private handleKeyEvent(e: KeyboardEvent) {
    e.stopPropagation();
    if (e.code === KeyCodes.Del) {
      const deleted = this.bezierPath?.deleteSelected();
      if (deleted) this.render();
    }
  }

  private handleDownAdd(pos: Point) {
    if (!this.bezierPath) this.bezierPath = new BezierPath(pos);
    else this.bezierPath.addPoint(pos);
    this.render();
  }

  private handleDownSelect(pos: Point) {
    if (!this.bezierPath) return false;
    const selected = this.bezierPath.selectNode(pos);
    if (selected) {
      this.canvasMode = CanvasMode.Dragging;
      this.canvas.style.cursor = CursorStyle.POINTER;
      this.render();
      return true;
    }
    return false;
  }

  private handleDownDeselect() {
    if (this.bezierPath?.haveSelectedNode) {
      this.bezierPath?.clearSelected();
      this.canvas.style.cursor = CursorStyle.DEFAULT;
      this.render();
      return true;
    }
    return false;
  }

  private updateSelected(pos: Point) {
    this.bezierPath?.updateSelected(pos);
    this.render();
  }

  private getMousePosition(e: MouseEvent) {
    let x =
      e.pageX ||
      e.clientX +
        document.body.scrollLeft +
        document.documentElement.scrollLeft;
    let y =
      e.pageY ||
      e.clientY + document.body.scrollTop + document.documentElement.scrollTop;

    x -= this.canvas.offsetLeft;
    y -= this.canvas.offsetTop;

    return new Point(x, y);
  }

  private attachKeyDownEvent() {
    window.onload = () => {
      let lastDownTarget: EventTarget | null;
      document.addEventListener(
        'mousedown',
        event => {
          lastDownTarget = event.target;
        },
        false,
      );

      document.addEventListener(
        'keydown',
        event => {
          if (lastDownTarget == this.canvas) {
            this.handleKeyEvent(event);
          }
        },
        false,
      );
    };
  }

  protected render() {
    super.render();
    //display line
    if (this.bezierPath && this.ctx) {
      this.bezierPath.draw(this.ctx);
    }
  }

  /**
   * Convert svg string to the array of bezier path points
   * @param svgString
   */
  private svgStringToBezierPath(svgString: string) {
    if (!this.canvasImage) throw 'Image must be loaded';

    const svgElem = parseStringToSVG(svgString);

    const paths = svgElem.getElementsByTagName('path');
    if (!paths.length) throw 'No path is present in svg';

    const path = paths[0];

    const d = path.getAttribute('d') || '';
    // split by line commands
    const commands = d.split(/(?=[MC])/);

    const initPoints: InitPoint[] = [];
    for (let i = 0; i < commands.length; i += 2) {
      // get numbers array from commands
      // move command - must have 2 points
      const mPoints = commands[i].slice(2, commands[i].length).match(/[^ ]+/g);
      // curve command - must have 6 points
      const cPoints = commands[i + 1]
        .slice(2, commands[i + 1].length)
        .match(/[^ ]+/g);

      if (!mPoints || !cPoints) throw 'Incorrect path: M or C is not present';

      if (mPoints.length !== 2) throw 'M command must contain 2 values';

      if (cPoints.length !== 6) throw 'C command must contain 6 values';

      const point: Position = {
        x:
          +mPoints[0] * this.canvasImage.imageScale +
          this.canvasImage.imageTranslate.x,
        y:
          +mPoints[1] * this.canvasImage.imageScale +
          this.canvasImage.imageTranslate.y,
      };
      const ctrl1: Position = {
        x:
          +cPoints[0] * this.canvasImage.imageScale +
          this.canvasImage.imageTranslate.x,
        y:
          +cPoints[1] * this.canvasImage.imageScale +
          this.canvasImage.imageTranslate.y,
      };
      const nextCtrl2: Position = {
        x:
          +cPoints[2] * this.canvasImage.imageScale +
          this.canvasImage.imageTranslate.x,
        y:
          +cPoints[3] * this.canvasImage.imageScale +
          this.canvasImage.imageTranslate.y,
      };
      const endPoint: Position = {
        x:
          +cPoints[4] * this.canvasImage.imageScale +
          this.canvasImage.imageTranslate.x,
        y:
          +cPoints[5] * this.canvasImage.imageScale +
          this.canvasImage.imageTranslate.y,
      };

      initPoints.push({
        point,
        ctrl1,
        nextCtrl2,
        endPoint,
      });
    }
    return initPoints;
  }
}
