export class Point {
  private _x = 0;
  private _y = 0;

  private radius = 7;
  private readonly selectedRadius = this.radius + 3;

  constructor(x: number, y: number, radius?: number) {
    this._x = x;
    this._y = y;
    if (radius) this.radius = radius;
  }

  get x(): number {
    return this._x;
  }

  get y(): number {
    return this._y;
  }

  setPoint(x: number, y: number): void {
    this._x = x;
    this._y = y;
  }

  draw(ctx: CanvasRenderingContext2D, isSelected = false): void {
    ctx.save();
    ctx.beginPath();
    const radius = isSelected ? this.radius + 2 : this.radius;
    ctx.arc(this.x, this.y, radius, 0, 2 * Math.PI);
    if (isSelected) ctx.fillStyle = 'red';
    ctx.fill();
    ctx.restore();
  }

  computeSlope(pt: Point): number {
    return (pt.y - this.y) / (pt.x - this.x);
  }

  contains(pt: Point): boolean {
    const xInRange =
      pt.x >= this.x - this.selectedRadius &&
      pt.x <= this.x + this.selectedRadius;
    const yInRange =
      pt.y >= this.y - this.selectedRadius &&
      pt.y <= this.y + this.selectedRadius;
    return xInRange && yInRange;
  }

  offsetFrom(pt: Point) {
    return {
      xDelta: pt.x - this.x,
      yDelta: pt.y - this.y,
    };
  }

  translate(xDelta: number, yDelta: number): void {
    this._x += xDelta;
    this._y += yDelta;
  }
}
