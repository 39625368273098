import { Point } from './Point';
import { Node } from './Node';
import { Position } from '@/safeAreaTool/types/Position';
import { InitPoint } from '@/safeAreaTool/types/InitPoint';

export class BezierPath {
  private head: Node | null = null;
  private tail: Node | null = null;

  private selectedNode: Node | null = null;

  constructor(startPoint?: Position) {
    if (startPoint) this.addPoint(startPoint);
  }

  public initFromArray(points: InitPoint[]) {
    if (!points.length) return;

    // 1. Add all points to bezier path, control points are default
    points.forEach(point => {
      this.addPoint(point.point);
    });
    this.addPoint(points[points.length - 1].endPoint);

    let current = this.head;
    let i = 0;
    // 2. Update control points
    while (current !== null) {
      if (i < points.length) {
        const point = points[i];
        current.ctrlPt1?.moveTo(point.ctrl1.x, point.ctrl1.y);

        if (current.nextNode) {
          current.nextNode.ctrlPt2?.moveTo(
            point.nextCtrl2.x,
            point.nextCtrl2.y,
          );
        }
      }
      i++;
      current = current.nextNode;
    }
  }

  public addPoint(pos: Position): Node {
    const newNode = new Node(new Point(pos.x, pos.y), this.tail);
    if (this.tail == null) {
      this.tail = newNode;
      this.head = newNode;
    } else {
      this.tail.nextNode = newNode;
      this.tail = newNode;
    }
    return newNode;
  }

  public draw(ctx: CanvasRenderingContext2D): void {
    if (this.head === null) return;

    let current: Node | null = this.head;
    while (current !== null) {
      current.draw(ctx);
      current = current.nextNode;
    }
  }

  public getNodeByPos(pos: Point): Node | null {
    let current = this.head;
    while (current !== null) {
      if (current.posIsOnNode(pos)) {
        return current;
      }
      current = current.nextNode;
    }
    return null;
  }

  public selectNode(pos: Point): boolean {
    const node = this.getNodeByPos(pos);
    if (!node) return false;

    node.selectPoint(pos);
    this.selectedNode = node;
    this.selectedNode.isSelected = true;
    return true;
  }

  public deleteSelected() {
    if (this.selectedNode) {
      return this.deletePoint(this.selectedNode.point);
    }
    return false;
  }

  public deletePoint(pos: Point): boolean {
    let current = this.head;
    while (current !== null) {
      if (current.pathPointIntersects(pos)) {
        const leftNeighbor = current.prevNode;
        const rightNeighbor = current.nextNode;

        // Middle case
        if (leftNeighbor && rightNeighbor) {
          leftNeighbor.nextNode = rightNeighbor;
          rightNeighbor.prevNode = leftNeighbor;
        }
        // HEAD CASE
        else if (!leftNeighbor) {
          current.ctrlPt1 = null;
          current.ctrlPt2 = null;
          this.head = rightNeighbor;
          if (this.head) {
            this.head.ctrlPt2 = null;
            this.head.prevNode = null;
          } else this.tail = null;
        }
        // TAIL CASE
        else if (!rightNeighbor) {
          this.tail = leftNeighbor;
          if (this.tail) {
            this.tail.ctrlPt1 = null;
            this.tail.nextNode = null;
          } else this.head = null;
        }
        return true;
      }
      current = current.nextNode;
    }
    return false;
  }

  public updateSelected(pos: Point): void {
    if (this.selectedNode) this.selectedNode.moveTo(pos);
  }

  public clearSelected(): void {
    if (this.selectedNode) this.selectedNode.isSelected = false;
    this.selectedNode = null;
  }

  public get haveSelectedNode() {
    return !!this.selectedNode;
  }

  /**
   * Convert bezier path to svg path taking into account image initial transformations
   * @param scale - image scale
   * @param translate - image translate
   */
  public toPath(scale: number, translate: { x: number; y: number }) {
    let path = '';

    let current = this.head;
    while (current !== null) {
      if (current.nextNode) {
        const startPoint = {
          x: (current.point.x - translate.x) / scale,
          y: (current.point.y - translate.y) / scale,
        };
        const ctrlPoint1 = {
          x: ((current.ctrlPt1?.x() || 0) - translate.x) / scale,
          y: ((current.ctrlPt1?.y() || 0) - translate.y) / scale,
        };
        const ctrlPoint2 = {
          x: ((current.nextNode?.ctrlPt2?.x() || 0) - translate.x) / scale,
          y: ((current.nextNode?.ctrlPt2?.y() || 0) - translate.y) / scale,
        };
        const endPoint = {
          x: (current.nextNode?.point.x - translate.x) / scale,
          y: (current.nextNode?.point.y - translate.y) / scale,
        };
        path += `M ${startPoint.x} ${startPoint.y} 
          C ${ctrlPoint1.x} ${ctrlPoint1.y} 
            ${ctrlPoint2.x} ${ctrlPoint2.y}
            ${endPoint.x} ${endPoint.y} `;
      }
      current = current.nextNode;
    }
    return path;
  }
}
