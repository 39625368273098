import { CanvasEvents } from '@/safeAreaTool/types/CanvasEvents';
import { CanvasImage } from '@/safeAreaTool/models/CanvasImage';

/**
 * Basic canvas class with default functionality
 * To be extended by specific implementations
 */
export class Canvas {
  protected readonly canvas: HTMLCanvasElement;
  protected ctx: CanvasRenderingContext2D | null = null;

  protected canvasImage?: CanvasImage;

  constructor(
    canvas: HTMLCanvasElement,
    imageUrl: string,
    safeAreaSvg?: string,
  ) {
    this.canvas = canvas;
    this.ctx = this.canvas.getContext('2d');
    if (!this.ctx) throw 'Canvas context is not defined';

    this.init(imageUrl, safeAreaSvg);
  }

  public attachEvent(eventType: CanvasEvents, handler: (evt: Event) => void) {
    this.canvas.addEventListener(eventType, e => handler(e));
  }

  public toSvg(): SVGElement {
    const xmlns = 'http://www.w3.org/2000/svg';
    const boxWidth = this.canvasImage?.imageElem?.width;
    const boxHeight = this.canvasImage?.imageElem?.height;

    const svgElem = document.createElementNS(xmlns, 'svg');
    svgElem.setAttributeNS(
      null,
      'viewBox',
      '0 0 ' + boxWidth + ' ' + boxHeight,
    );
    svgElem.setAttributeNS(null, 'width', `${boxWidth}px`);
    svgElem.setAttributeNS(null, 'height', `${boxHeight}px`);
    svgElem.setAttribute('xmlns', xmlns);

    return svgElem;
  }

  /**
   * Overload this function to make additional actions during init
   * @param imageUrl - image to load
   * @param safeAreaSvg - when present, svg area will be initialized from this string
   */
  protected async init(imageUrl: string, safeAreaSvg?: string) {
    await this.initImage(imageUrl);
  }

  /**
   *  Render function - overload to add additonal logic during render
   */
  protected render() {
    if (!this.ctx) return;
    this.ctx.clearRect(0, 0, this.canvas.width, this.canvas.height);
    this.ctx.fillStyle = 'white';
    this.ctx.fillRect(0, 0, this.canvas.width, this.canvas.height);
    //display image
    if (this.canvasImage && this.canvasImage.imageElem) {
      this.ctx.drawImage(
        this.canvasImage.imageElem,
        this.canvasImage.imageTranslate.x,
        this.canvasImage.imageTranslate.y,
        this.canvasImage.imageDesiredSize.width,
        this.canvasImage.imageDesiredSize.height,
      );
    }
  }

  private async initImage(imageUrl: string) {
    this.canvasImage = new CanvasImage(imageUrl, {
      width: this.canvas.width,
      height: this.canvas.height,
    });
    await this.canvasImage.initImageElem();
    // display image
    this.render();
  }
}
