import { Node } from './Node';
import { Point } from './Point';

export class ControlPoint {
  private angle: number;
  private magnitude: number;
  private owner: Node;
  private isFirst: boolean;

  private readonly radius = 4;

  constructor(angle: number, magnitude: number, owner: Node, isFirst: boolean) {
    this.angle = angle;
    this.magnitude = magnitude;
    this.owner = owner;
    this.isFirst = isFirst;
    // this.updateNeighbor();
  }

  public contains(pt: Point): boolean {
    return this.asPoint().contains(pt);
  }

  public x() {
    return (this.origin()?.x || 0) + this.xDelta();
  }

  public y() {
    return (this.origin()?.y || 0) + this.yDelta();
  }

  public draw(ctx: CanvasRenderingContext2D) {
    const startPt = this.origin();
    if (!startPt) return;

    ctx.save();
    ctx.fillStyle = 'slategrey';
    ctx.strokeStyle = 'slategrey';

    ctx.beginPath();
    const endPt = this.asPoint();
    ctx.moveTo(startPt.x, startPt.y);
    ctx.lineTo(endPt.x, endPt.y);
    ctx.stroke();
    endPt.draw(ctx);
    ctx.restore();
  }

  public offsetFrom(pt: Point) {
    return this.asPoint().offsetFrom(pt);
  }

  public translate(xDelta: number, yDelta: number) {
    const newLoc = this.asPoint();
    newLoc.translate(xDelta, yDelta);
    const dist = this.origin()?.offsetFrom(newLoc);
    if (!dist) return;
    this.computeMagnitudeAngleFromOffset(dist.xDelta, dist.yDelta);
    // this.updateNeighbor();
  }

  public moveTo(x: number, y: number) {
    this.translate(x - this.x(), y - this.y());
  }

  private xDelta() {
    return this.magnitude * Math.cos(this.angle);
  }

  private yDelta() {
    return this.magnitude * Math.sin(this.angle);
  }

  private origin() {
    if (this.owner) return new Point(this.owner.point.x, this.owner.point.y);
    return null;
  }

  private asPoint() {
    return new Point(this.x(), this.y(), this.radius);
  }

  private computeMagnitudeAngleFromOffset(xDelta: number, yDelta: number) {
    this.magnitude = Math.sqrt(Math.pow(xDelta, 2) + Math.pow(yDelta, 2));
    const tryAngle = Math.atan(yDelta / xDelta);
    if (!isNaN(tryAngle)) {
      this.angle = tryAngle;
      if (xDelta < 0) this.angle += Math.PI;
    }
  }

  private updateNeighbor() {
    const neighbor = this.isFirst ? this.owner.ctrlPt2 : this.owner.ctrlPt1;
    if (neighbor) neighbor.setAngle(this.angle + Math.PI);
  }

  private setAngle(deg: number) {
    if (this.angle !== deg) this.angle = deg;
  }
}
