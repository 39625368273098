import { setupVue } from '@/safeAreaTool/VueSetup';
import { SafeLineCanvas } from '@/safeAreaTool/models/canvas/SafeLineCanvas';
import { ToolMode } from '@/safeAreaTool/types/types';
import { CanvasEvents } from '@/safeAreaTool/types/CanvasEvents';
import { Canvas } from '@/safeAreaTool/models/canvas/Canvas';
import { SafeRectCanvas } from '@/safeAreaTool/models/canvas/SafeRectCanvas';

interface SubmitEventData {
  safeArea: SVGElement | null;
}

interface InitOptions {
  imageUrl: string;
  safeLine: boolean;
  safeAreaSvg?: string;
}

/**
 * Main class that exposes public api
 */
export class SafeAreaTool {
  private _imageUrl: string | null = null;
  private _toolMode: ToolMode | null = null;
  private _canvas: Canvas | null = null;

  public async init(
    elementId: string,
    initOptions: InitOptions,
  ): Promise<void> {
    this._imageUrl = initOptions.imageUrl;
    this._toolMode = initOptions.safeLine
      ? ToolMode.SAFE_LINE
      : ToolMode.SAFE_RECT;

    await this.initCanvas(elementId, initOptions.safeAreaSvg);
  }

  private async initCanvas(elementId: string, safeAreaSvg?: string) {
    const canvasElem = await setupVue(elementId);

    switch (this.toolMode) {
      case ToolMode.SAFE_LINE:
        this._canvas = new SafeLineCanvas(
          canvasElem,
          this._imageUrl || '',
          safeAreaSvg,
        );
        break;
      case ToolMode.SAFE_RECT:
        this._canvas = new SafeRectCanvas(
          canvasElem,
          this._imageUrl || '',
          safeAreaSvg,
        );
        break;
    }
    this._canvas?.attachEvent(CanvasEvents.SUBMIT_SAFE_AREA, e =>
      this.handleSubmit(e),
    );
  }

  private handleSubmit(e: Event) {
    window.dispatchEvent(
      new CustomEvent<SubmitEventData>(CanvasEvents.SUBMIT_SAFE_AREA, {
        detail: {
          safeArea: this._canvas?.toSvg() || null,
        },
      }),
    );
  }

  get imageUrl(): string | null {
    return this._imageUrl;
  }

  get toolMode(): ToolMode | null {
    return this._toolMode;
  }
}
