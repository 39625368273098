export enum ToolMode {
  SAFE_LINE,
  SAFE_RECT,
}

export enum CanvasMode {
  Adding,
  Dragging,
}

export enum KeyCodes {
  Del = 'Delete',
}

export enum MouseButtons {
  LEFT,
}

export enum CursorStyle {
  DEFAULT = 'default',
  POINTER = 'pointer',
}
