








import { defineComponent, SetupContext } from '@vue/composition-api';

const Component = defineComponent({
  name: 'Controls',

  setup(_, { emit }: SetupContext) {
    function onSubmit() {
      emit('submit-safe-area');
    }

    return {
      onSubmit,
    };
  },
});

export default Component;
