




import { defineComponent, ref } from '@vue/composition-api';

const Component = defineComponent({
  name: 'Canvas',

  props: {
    width: String,
    height: String,
  },

  setup() {
    const htmlCanvas = ref<HTMLCanvasElement | null>(null);

    return {
      htmlCanvas,
    };
  },
});

export default Component;
