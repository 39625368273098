import { Size } from '@/safeAreaTool/types/Size';

export class CanvasImage {
  private _imageElem: HTMLImageElement | null = null;
  private _containerSize: Size;

  private _imageUrl = '';
  private _imageScale = 1;

  private _imageTranslate = {
    x: 0,
    y: 0,
  };

  private _imageDesiredSize: Size = {
    width: 0,
    height: 0,
  };

  constructor(imageUrl: string, containerSize: Size) {
    this._imageUrl = imageUrl;
    this._containerSize = containerSize;
  }

  public async initImageElem() {
    this._imageElem = await this.loadImage();
    if (this._imageElem) {
      this._imageScale = this.calcImageScale();
      this._imageDesiredSize = this.calcDesiredSize();
      this._imageTranslate = this.calcTranslate();
    }
  }

  private loadImage(): Promise<HTMLImageElement | null> {
    return new Promise((resolve, reject) => {
      if (this.imageUrl) {
        const img = document.createElement('img');
        img.src = this.imageUrl;
        img.crossOrigin = '*';
        img.onerror = (e, source, lineno, colno, error) => {
          this._imageElem = null;
          reject(`Cannot load specified image: ${error}`);
        };
        img.onload = () => {
          resolve(img);
        };
      } else {
        resolve(null);
      }
    });
  }

  private calcDesiredSize() {
    if (!this._imageElem) return this._imageDesiredSize;

    return {
      width: this._imageElem.width * this.imageScale,
      height: this._imageElem.height * this.imageScale,
    };
  }

  private calcTranslate() {
    if (!this._imageElem) return this._imageTranslate;

    return {
      x: (this._containerSize.width - this.imageDesiredSize.width) / 2,
      y: (this._containerSize.height - this.imageDesiredSize.height) / 2,
    };
  }

  private calcImageScale() {
    if (!this._imageElem) return 1;

    const canvasPaddingRatio = 0.1; // 10%
    const canvasPadding = {
      width: this._containerSize.width * canvasPaddingRatio,
      height: this._containerSize.height * canvasPaddingRatio,
    };
    const objDesiredSize = {
      width: this._containerSize.width - 2 * canvasPadding.width,
      height: this._containerSize.height - 2 * canvasPadding.height,
    };

    return Math.min(
      objDesiredSize.width / (this._imageElem.width || 1),
      objDesiredSize.height / (this._imageElem.height || 1),
    );
  }

  get imageElem(): HTMLImageElement | null {
    return this._imageElem;
  }

  get imageDesiredSize(): Size {
    return this._imageDesiredSize;
  }

  get imageUrl(): string {
    return this._imageUrl;
  }

  get imageScale(): number {
    return this._imageScale;
  }

  get imageTranslate(): { x: number; y: number } {
    return this._imageTranslate;
  }
}
