import { Canvas } from '@/safeAreaTool/models/canvas/Canvas';
import Cropper from 'cropperjs';
import 'cropperjs/dist/cropper.css';
import { CropValues } from '@/safeAreaTool/types/CropValues';
import {
  parseStringToSVG,
  svgLengthToVal,
} from '@/safeAreaTool/utils/svgUtils';

/**
 * Canvas with cropper logic
 */
export class SafeRectCanvas extends Canvas {
  private cropper: Cropper | null = null;
  private cropValues: CropValues = {
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  };

  public toSvg(): SVGElement {
    const svgElem = super.toSvg();
    const xmlns = 'http://www.w3.org/2000/svg';

    const g = document.createElementNS(xmlns, 'g');
    svgElem.appendChild(g);

    const path = document.createElementNS(xmlns, 'path');
    path.setAttributeNS(null, 'fill', 'none');
    path.setAttributeNS(null, 'stroke', '#000000');
    path.setAttributeNS(null, 'stroke-width', '1px');
    path.setAttributeNS(null, 'd', this.cropValuesToPathString());

    g.appendChild(path);
    return svgElem;
  }

  /**
   * Calc cropped area bounds according to image initial transformations
   */
  private getUnscaledCropValues(): CropValues {
    if (!this.canvasImage) return this.cropValues;

    const imgTranslate = this.canvasImage.imageTranslate;
    const imgScale = this.canvasImage.imageScale;

    return {
      x: (this.cropValues.x - imgTranslate.x) / imgScale,
      y: (this.cropValues.y - imgTranslate.y) / imgScale,
      width: this.cropValues.width / imgScale,
      height: this.cropValues.height / imgScale,
    };
  }

  private cropValuesToPathString() {
    const cropValues = this.getUnscaledCropValues();
    const leftTop = {
      x: cropValues.x,
      y: cropValues.y,
    };
    const rightTop = {
      x: cropValues.x + cropValues.width,
      y: cropValues.y,
    };
    const rightBottom = {
      x: cropValues.x + cropValues.width,
      y: cropValues.y + cropValues.height,
    };
    const leftBottom = {
      x: cropValues.x,
      y: cropValues.y + cropValues.height,
    };
    return `
      M ${leftTop.x} ${leftTop.y} L ${rightTop.x} ${rightTop.y}
      M ${rightTop.x} ${rightTop.y} L ${rightBottom.x} ${rightBottom.y}
      M ${rightBottom.x} ${rightBottom.y} L ${leftBottom.x} ${leftBottom.y}
      M ${leftBottom.x} ${leftBottom.y} L ${leftTop.x} ${leftTop.y}
    `;
  }

  protected async init(imageUrl: string, safeAreaSvg?: string) {
    await super.init(imageUrl);
    const cropValues = safeAreaSvg
      ? this.svgStringToCropValues(safeAreaSvg)
      : undefined;
    this.initCropper(cropValues);
  }

  private initCropper(cropValues?: CropValues) {
    this.cropper = new Cropper(this.canvas, {
      viewMode: 1, // restrict the crop box not to exceed the size of the canvas
      rotatable: false,
      movable: false,
      scalable: false,
      zoomable: false,
      zoomOnTouch: false,
      zoomOnWheel: false,
      data: {
        ...(cropValues || {}),
      },
      crop: event => {
        this.cropValues.x = event.detail.x;
        this.cropValues.y = event.detail.y;
        this.cropValues.width = event.detail.width;
        this.cropValues.height = event.detail.height;
      },
    });
  }

  private svgStringToCropValues(svgString: string) {
    if (!this.canvasImage) throw 'Image must be loaded';

    const svgElem = parseStringToSVG(svgString);
    const rects = svgElem.getElementsByTagName('rect');
    if (!rects.length) throw 'No rect is present in svg';

    const rect = rects[0];

    return {
      x:
        svgLengthToVal(rect.x) * this.canvasImage.imageScale +
        this.canvasImage.imageTranslate.x,
      y:
        svgLengthToVal(rect.y) * this.canvasImage.imageScale +
        this.canvasImage.imageTranslate.y,
      width: svgLengthToVal(rect.width) * this.canvasImage.imageScale,
      height: svgLengthToVal(rect.height) * this.canvasImage.imageScale,
    };
  }
}
