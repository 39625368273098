import { Point } from './Point';
import { ControlPoint } from './ControlPoint';

/**
 * Represents point with control points on the path
 */
export class Node {
  private readonly _point: Point;
  private selectedPoint?: Point | ControlPoint;

  //control point related to prev line
  private _ctrlPt1: ControlPoint | null = null;
  //control point related to next line
  private _ctrlPt2: ControlPoint | null = null;
  private _prevNode: Node | null = null;
  private _nextNode: Node | null = null;

  private _isSelected = false;

  constructor(pt: Point, prev: Node | null) {
    this._point = pt;
    this.prevNode = prev;

    if (this.prevNode) {
      // Make initial line straight and with controls of length 20.
      const slope = this.point.computeSlope(this.prevNode.point);
      let angle = Math.atan(slope);

      if (this.prevNode.point.x > this.point.x) angle *= -1;

      const magnitude = 20;
      this.prevNode.ctrlPt1 = new ControlPoint(
        angle,
        magnitude,
        this.prevNode,
        true,
      );
      this.ctrlPt2 = new ControlPoint(angle - Math.PI, magnitude, this, false);
    }
  }

  public posIsOnNode(pos: Point): boolean {
    return (
      this.pathPointIntersects(pos) ||
      (!!this.ctrlPt1 && this.ctrlPt1.contains(pos)) ||
      (!!this.ctrlPt2 && this.ctrlPt2.contains(pos))
    );
  }

  public selectPoint(pos: Point): boolean {
    if (this.pathPointIntersects(pos)) {
      this.selectedPoint = this.point;
      return true;
    } else if (this.ctrlPt1 && this.ctrlPt1.contains(pos)) {
      this.selectedPoint = this.ctrlPt1;
      return true;
    } else if (this.ctrlPt2 && this.ctrlPt2.contains(pos)) {
      this.selectedPoint = this.ctrlPt2;
      return true;
    }
    return false;
  }

  public pathPointIntersects(pos: Point): boolean {
    return this.point && this.point.contains(pos);
  }

  public moveTo(pos: Point): void {
    const dist = this.selectedPoint?.offsetFrom(pos);
    if (!dist) return;
    this.selectedPoint?.translate(dist.xDelta, dist.yDelta);
  }

  public draw(ctx: CanvasRenderingContext2D) {
    ctx.fillStyle = 'black';
    this.point.draw(ctx, this.isSelected);
    // Draw control points if we have them
    if (this.ctrlPt1) this.ctrlPt1.draw(ctx);
    if (this.ctrlPt2) this.ctrlPt2.draw(ctx);

    // If there are at least two points, draw curve.
    if (this.prevNode) {
      if (this.prevNode.ctrlPt1 && this.ctrlPt2) {
        this.drawCurve(
          ctx,
          this.prevNode.point,
          this.point,
          this.prevNode.ctrlPt1,
          this.ctrlPt2,
        );
      }
    }
  }

  private drawCurve(
    ctx: CanvasRenderingContext2D,
    startPt: Point,
    endPt: Point,
    ctrlPt1: ControlPoint,
    ctrlPt2: ControlPoint,
  ): void {
    ctx.save();
    ctx.fillStyle = 'black';
    ctx.strokeStyle = 'black';
    ctx.beginPath();
    ctx.moveTo(startPt.x, startPt.y);
    ctx.bezierCurveTo(
      ctrlPt1.x(),
      ctrlPt1.y(),
      ctrlPt2.x(),
      ctrlPt2.y(),
      endPt.x,
      endPt.y,
    );
    ctx.stroke();
    ctx.restore();
  }

  get prevNode(): Node | null {
    return this._prevNode;
  }

  set prevNode(prev: Node | null) {
    this._prevNode = prev;
  }

  get nextNode(): Node | null {
    return this._nextNode;
  }

  set nextNode(next: Node | null) {
    this._nextNode = next;
  }

  get point(): Point {
    return this._point;
  }

  get ctrlPt1(): ControlPoint | null {
    return this._ctrlPt1;
  }

  set ctrlPt1(value: ControlPoint | null) {
    this._ctrlPt1 = value;
  }

  get ctrlPt2(): ControlPoint | null {
    return this._ctrlPt2;
  }

  set ctrlPt2(value: ControlPoint | null) {
    this._ctrlPt2 = value;
  }

  get isSelected(): boolean {
    return this._isSelected;
  }

  set isSelected(value: boolean) {
    this._isSelected = value;
  }
}
