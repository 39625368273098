import Vue from 'vue';
import App from '../App.vue';
import VueCompositionAPI from '@vue/composition-api';
import vuetify from '@/plugins/vuetify';

/**
 * Init Vue components when API function is called
 * @param elementId - element id where to attach vue components
 */
export function setupVue(elementId: string): Promise<HTMLCanvasElement> {
  return new Promise<HTMLCanvasElement>((resolve, reject) => {
    Vue.config.productionTip = false;
    Vue.use(VueCompositionAPI);

    new Vue({
      vuetify,
      render: h => h(App),
      mounted: function() {
        // when all components are mounted, return initialized canvas
        if (!this.$children.length || !(this.$children[0] as any).canvas)
          reject('No canvas element is present');
        resolve((this.$children[0] as any).canvas.$el);
      },
    }).$mount(`#${elementId}`);
  });
}
