

















import Canvas from './components/CanvasComponent.vue';
import Controls from './components/Controls.vue';
import { defineComponent, onMounted, ref } from '@vue/composition-api';
import Vue from 'vue';
import { CanvasEvents } from '@/safeAreaTool/types/CanvasEvents';

export default defineComponent({
  name: 'App',

  components: {
    Canvas,
    Controls,
  },

  setup() {
    const tool = ref<HTMLElement | null>(null);
    const canvas = ref<Vue | null>(null);
    let canvasWidth = ref(tool.value?.offsetWidth);
    let canvasHeight = ref(tool.value?.offsetHeight);

    onMounted(() => {
      if (tool.value) {
        canvasWidth.value = tool.value.offsetWidth;
        canvasHeight.value = tool.value.offsetHeight * 0.93; //93%
      }
    });

    function submitSafeArea() {
      if (canvas.value) {
        canvas.value.$el.dispatchEvent(
          new Event(CanvasEvents.SUBMIT_SAFE_AREA),
        );
      }
    }

    return {
      tool,
      canvas,
      canvasWidth,
      canvasHeight,
      submitSafeArea,
    };
  },
});
